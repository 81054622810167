import React, {useEffect, useState} from 'react';
import './style.scss';
import Particle from "../../components/particle";
import { JSX } from 'react/jsx-runtime';

const Homepage: React.FC = () => {
    const [particles, setParticles] = useState<JSX.Element[]>([]);

    useEffect(() => {
        for (let i = 0; i < 100; i++) {
            const newParticle = Particle(i,Math.random() * 100, Math.random() * 100);
            setParticles((part) => [...part, newParticle]);
        }
    }, []);

    return (
        <div className="dark-mode">
            <span id="wombawe">Espedal</span>
            {particles}
        </div>
    );
};

export default Homepage;
