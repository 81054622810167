import React from "react";
import "./style.scss";

const Particle = (key: number, top: number, right: number) => {
    return (
        <div key={key} className="particle" style={{ top: top + "vh", right: right + "vw" }} />
    );
}

export default Particle;
